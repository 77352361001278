import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GuestIframe from './guest-iframe';
import './host.css';
import redux, { RootState } from './redux';
import { DashboardWindow, HostProps } from './types';
import utils from './utils';

const Host = ({
  appName,
  appPath,
  appURL,
  currentCustomerGroup,
  environmentVariables,
}: HostProps) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const dispatch: typeof redux.store.dispatch = useDispatch();
  const guestAppInitError = useSelector(
    (state: RootState) => state.guestApp.initError,
  );
  const resetPageToRoot = () => {
    window.history.replaceState(
      'customer-group:changed',
      '',
      appPath.split('/').slice(0, 2).join('/'),
    );
  };
  useEffect(() => {
    document.body.classList.add('mfe-guest-iframe');
    window.addEventListener('customer-group:changed', resetPageToRoot);
    if (iframeRef?.current) {
      (window as DashboardWindow).groupSwitcher.setDropdownBackdrop();
      const queryParams = new URLSearchParams();
      queryParams.append('customer_group_uuid', currentCustomerGroup.uuid);
      utils.webAppMessaging.adapter
        .loadGuestApp({
          envVars: environmentVariables,
          iframeElement: iframeRef.current,
          name: appName,
          onGuestMessageReceived: (payload) =>
            utils.webAppMessaging.onGuestMessageReceived(dispatch, payload),
          queryParams,
          url: `${appURL}${appPath}`,
        })
        .catch((error) => {
          utils.webAppMessaging.onStartupError(dispatch, error.message);
        });
    }
    return () => {
      window.removeEventListener('customer-group:changed', resetPageToRoot);
    };
  }, []);
  if (guestAppInitError) {
    return null;
  }
  return <GuestIframe ref={iframeRef} source={appURL} />;
};

export default Host;
